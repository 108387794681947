<template>
  <v-dialog
    persistent
    @keydown.esc="close"
    v-model="bDialogAddTransation"
    :width="screenWidth + '%'"
  >
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-transation">
          <p class="title-add-transation">Registro transacción</p>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="content-text-fields">
          <v-container>
            <v-row>
              <!-- #region Tipo de transacción -->
              <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                <v-select
                  v-model="sTransationType"
                  :items="itemTransationType"
                  item-text="text"
                  item-value="value"
                  class="global-select"
                  color="var(--primary-color-border-input)"
                  background-color="var(--primary-color-menu)"
                  outlined
                  placeholder="Seleccionar tipo de transacción"
                  :persistent-placeholder="true"
                  @change="validateForm()"
                >
                  <template slot="label">
                    <span
                      >Tipo de transacción
                      <span class="important-data">*</span></span
                    >
                  </template>
                </v-select>
              </v-col>
              <!-- #endregion Tipo de transacción -->

              <!-- #region nombre del benificiario -->
              <v-col v-if="!bTransfer" cols="12" sm="4" md="4" lg="4" xl="4">
                <v-text-field
                  v-model="sBeneficiaryName"
                  outlined
                  class="global-text-field global-text-field-date"
                  label="Nombre beneficiario"
                  :persistent-placeholder="true"
                  placeholder="Nombre beneficiario"
                  color="var(--primary-color-border-menu-profile)"
                  background-color="var(--primary-color-menu)"
                  @keyup="validateForm()"
                >
                  <template slot="label">
                    <span>
                      Nombre beneficiario
                      <span class="important-data">*</span>
                    </span>
                  </template>
                </v-text-field>
              </v-col>
              <!-- #endregion nombre del benificiario -->

              <!-- #region Cuenta   -->
              <v-col v-if="bTransfer" cols="12" sm="4" md="4" lg="4" xl="4">
                <v-select
                  :loading="bLoadAccounts"
                  :disabled="!sTransationType"
                  v-model="sAccountId"
                  :items="itemsAccounts"
                  item-text="sName"
                  item-value="sAccountId"
                  :persistent-placeholder="true"
                  placeholder="Cuenta destino"
                  class="global-select"
                  color="var(--primary-color-border-input)"
                  background-color="var(--primary-color-menu)"
                  outlined
                  @change="validateForm()"
                >
                  <template slot="label">
                    <span>
                      Cuenta destino
                      <span class="important-data"> * </span>
                    </span>
                  </template>
                </v-select>
              </v-col>
              <!-- #endregion Cuenta   -->

              <!-- #region Tipo de cambio -->
              <v-col
                v-if="bTransfer && bCurrencyMix"
                cols="12"
                sm="4"
                md="4"
                lg="4"
                xl="4"
              >
                <v-text-field-currency
                  v-model="dExchangeRate"
                  outlined
                  label="Tipo de cambio"
                  placeholder="0.00"
                  :bImportantData="true"
                  :options="oOptionCurrencyExchangeRateGlobal"
                />
              </v-col>
              <!-- #endregion Tipo de cambio -->

              <!-- #region Cantidad asociada -->
              <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                <v-text-field-currency
                  v-model="dAmount"
                  :disabled="bTransfer ? !sAccountId : false"
                  outlined
                  label="Cantidad asociada"
                  placeholder="0.00"
                  :bImportantData="true"
                  :options="oCurrencyOption"
                />
              </v-col>
              <!-- #endregion Cantidad asociada -->

              <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                <div class="content-filter-date" @click="menu = true">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dates"
                        chips
                        readonly
                        small-chips
                        outlined
                        clearable
                        class="global-text-field global-text-field-date"
                        label="Fecha de transacción"
                        placeholder="Seleccionar fecha de transacción"
                        :persistent-placeholder="true"
                        color="var(--primary-color-border-menu-profile)"
                        background-color="var(--primary-color-menu)"
                        v-bind="attrs"
                        v-on="on"
                        @click="validateForm()"
                      >
                        <template slot="append">
                          <v-icon>mdi-calendar-blank</v-icon>
                        </template>
                        <template slot="label">
                          <span
                            >Fecha de transacción
                            <span class="important-data">*</span></span
                          >
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dates"
                      :max="sMinDate"
                      no-title
                      locale="es"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save((dates = ''))"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates)"
                      >
                        <span>Guardar</span>
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="sDescription"
                  label="Descripción"
                  auto-grow
                  placeholder="Descripción..."
                  class="global-text-field"
                  color="var(--primary-color-border-input)"
                  background-color="var(--primary-color-menu)"
                  persistent-placeholder
                  maxlength="255"
                  outlined
                  rows="4"
                  row-height="50"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card-text>

      <v-card-text>
        <v-divider class="divider-sidebar-global mb-3"></v-divider>

        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn
              class="global-btn-primary"
              :disabled="!bAdd"
              :loading="bLoading"
              @click="setRegistred()"
              >Registrar transacción
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    bDialogAddTransation: Boolean,
    sCurrencyCode: String,
  },
  data() {
    return {
      screenWidth: 0,
      bAdd: false,
      bLoading: false,
      sTransationType: null,
      itemTransationType: [
        { text: "Depósito", value: "DEPOSIT" },
        { text: "Retiro", value: "WITHDRAW" },
        { text: "Traspaso de fondos", value: "TRANSFER" },
      ],
      sBeneficiaryName:"",
      bTransfer: false,
      bCurrencyMix: false,
      sCurrencyIdFromAccountDetail: "",
      bLoadAccounts: false,
      sAccountId: null,
      itemsAccounts: [],
      dAmount: null,
      oCurrencyOption: {},
      dCurrency: null,
      dExchangeRate: null,
      sDescription: "",
      menu: false,
      dates: "",
      sMinDate: new Date().toISOString().slice(0, 10),
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();
  },
  methods: {
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 80;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 60;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 60;
      }
    },
    close() {
      this.$emit("setDialogAddTransation");
      this.bAdd = false;
      this.bLoading = false;
      this.sAccountType = null;
      this.sBeneficiaryName = "";
      this.sTransationType = null;
      this.dAmount = null;
      this.dExchangeRate = null;
      this.bTransfer = false;
      this.sAccountId = null;
      this.bCurrencyMix = null;
      this.sDescription = "";
      this.dates = "";
    },
    setRegistred() {
      this.bLoading = true;
      if (this.bTransfer) {
        this.setTransfer();
      } else {
        this.setTransation();
      }
    },
    setTransation() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminAccount) {
            const payload = {
                dAmount: this.dAmount,
                sTransactionType: this.sTransationType,
                sDescription: this.sDescription,
                tTransactionDate: this.dates,
                sBeneficiaryName: this.sBeneficiaryName,
              },
              config = {
                headers: {
                  Authorization: "Bearer " + this.$store.state.sToken,
                },
              };
            DB.post(
              `${URI}/api/sp/v1/accounts/${this.$route.params.id}/transactions`,
              payload,
              config
            )
              .then((response) => {
                this.Success(response.data.message);
                this.close();
                this.$store.commit("refresher", true);
              })
              .catch((error) => {
                this.bLoading = false;
                this.Error(error.response.data);
              });
          } else {
            this.Error(this.$store.state.oError403);
            this.close();
          }
        })
        .catch((err) => {
          this.bLoading = false;
          this.Error(err);
        });
    },
    setTransfer() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminAccount) {
            const payload = {
                sDestinationAccountId: this.sAccountId,
                dAmount: this.dAmount,
                dExchangeRate: this.dExchangeRate ? this.dExchangeRate : 0,
                tTransactionDate: this.dates,
                sDescription: this.sDescription,
              },
              config = {
                headers: {
                  Authorization: "Bearer " + this.$store.state.sToken,
                },
              };
            DB.put(
              `${URI}/api/sp/v1/accounts/${this.$route.params.id}/transactions`,
              payload,
              config
            )
              .then((response) => {
                this.Success(response.data.message);
                this.close();
                this.$store.commit("refresher", true);
              })
              .catch((error) => {
                this.bLoading = false;
                this.Error(error.response.data);
              });
          } else {
            this.Error(this.$store.state.oError403);
            this.close();
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    changeMontoGlobal(value) {
      if (value !== "") {
        this.sCurrency = parseFloat(value);
      } else {
        this.sCurrency = 0;
      }
      this.validateForm();
    },
    validateForm() {
      if (this.bTransfer) {
        if (this.bCurrencyMix) {
          this.bAdd =
            this.sTransationType !== null &&
            this.sAccountId !== null &&
            this.dAmount > 0 &&
            this.dExchangeRate > 0 &&
            this.dates !== "" &&
            this.dates !== null;
        } else {
          this.bAdd =
            this.sTransationType !== null &&
            this.sAccountId !== null &&
            this.dAmount > 0 &&
            this.dates !== "" &&
            this.dates !== null;
        }
      } else {
        this.bAdd =
          this.dAmount > 0 &&
          this.sBeneficiaryName !== "" &&
          this.sTransationType !== null &&
          this.dates !== "" &&
          this.dates !== null;
      }
    },
    save(date) {
      this.$refs.menu.save(date);
      // let fecha_inicial = this.dates[0];
      // let fecha_final = this.dates[1];

      // if (this.dates.length > 1) {
      //   if (Date.parse(fecha_inicial) > Date.parse(fecha_final)) {
      //     let lastDate = this.dates[1];
      //     this.dates = [];
      //     this.dates[0] = lastDate;
      //   } else {
      //     this.$emit("setDate", {
      //       tStart: fecha_inicial,
      //       tEnd: fecha_final,
      //     });
      //   }
      // }
    },
    getAccounts() {
      this.bLoadAccounts = true;
      this.itemsAccounts = [];
      DB.get(`${URI}/api/sp/v1/accounts`, {
        params: {
          bSort: true,
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.bLoadAccounts = false;

          this.sCurrencyIdFromAccountDetail = response.data.results.find(
            (e) => e.sAccountId === this.$route.params.id
          ).oCurrency.sCurrencyId;

          this.itemsAccounts = response.data.results;
          this.getCurrencyOptions(this.$route.params.id);
          this.itemsAccounts = this.itemsAccounts.filter(
            (e) => e.sAccountId !== this.$route.params.id
          );
        })
        .catch((error) => {
          this.bLoadAccounts = false;
          this.Error(error.response.data);
        });
    },
    getCurrencyOptions(sAccountId) {
      let dAmountTemp = this.itemsAccounts.find(
        (eAccount) => eAccount.sAccountId === sAccountId
      ).oCurrency.sCurrencyId;
      switch (dAmountTemp) {
        case "cf37a729-0384-4ae0-8be8-60575e60c603": //MXN
          this.oCurrencyOption = this.oOptionCurrencyMXNGlobal;
          break;
        case "ca3bf61e-6fb1-4ff3-adfd-1cb5c6b99c48": //USD
          this.oCurrencyOption = this.oOptionCurrencyUSDGlobal;
          break;

        default:
          this.oCurrencyOption = this.oOptionCurrencyMXNGlobal;
          break;
      }
    },
  },
  computed: {
    bShowAccount() {
      return this.$store.state.bShowAccount;
    },
    bAdminAccount() {
      return this.$store.state.bAdminAccount;
    },
  },
  watch: {
    bDialogAddTransation() {
      if (this.bDialogAddTransation) {
        this.getAccounts();
      }
    },
    sTransationType() {
      if (this.sTransationType === "TRANSFER") {
        this.bTransfer = true;
      } else {
        this.bTransfer = false;
        this.sAccountId = null;
      }
      this.validateForm();
    },
    sAccountId() {
      if (this.sAccountId) {
        let dAmountTemp = this.itemsAccounts.find(
          (eAccount) => eAccount.sAccountId === this.sAccountId
        ).oCurrency.sCurrencyId;

        if (dAmountTemp !== this.sCurrencyIdFromAccountDetail) {
          this.bCurrencyMix = true;
        } else {
          this.bCurrencyMix = false;
          this.dExchangeRate = null;
        }
        // this.getCurrencyOptions(this.sAccountId);
        this.validateForm();
      }
    },
    dAmount() {
      this.validateForm();
    },
    dExchangeRate() {
      this.validateForm();
    },
    dates() {
      this.validateForm();
    },
  },
};
</script>
<style scoped>
.title-add-transation {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
}

.btn-icon-color-dynamic {
  border: solid var(--primary-color-text) 1px;
  border-radius: 100%;
  margin-top: -5px;
  margin-bottom: 5px;
  height: 26px;
  width: 26px;
}

.icon-color-dynamic {
  width: 100%;
  height: 100%;
}

.btn-arrow-down-color {
  margin-top: -10px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.content-title-transation {
  width: 100%;
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}

.content-btn-primary {
  width: 250px;
}

.content-btn-second {
  width: 250px;
  margin-right: 15px;
}

.content-btns-picker-color {
  width: 50%;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-btn-primary {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
